// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.cdnfonts.com/css/roboto);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#root{\n  background-color: #161718;\n  height: 100%;\n}\n.App {\n  background-color: #161718;\n  text-align: center;\n  font-family: 'Roboto', sans-serif;\n  height: 100%;\n}\np{\n  font-size: 18px;\n}\n/*************** For Contact page *************/\n#contact-form div{\n  text-align: left;\n}\n#contact-form div label{\n  margin-left: 10px;\n}\n#contact-form .form-control:focus{\n  border-color: white;\n  box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.5);\n}\n#hide-inputs-btn:hover{\n  background-color: white !important;\n  color: #161718 !important;\n  border: 1px solid #161718 !important;\n  margin-bottom: 20px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iCAAiC;EACjC,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA,+CAA+C;AAC/C;EACE,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,iDAAiD;AACnD;AACA;EACE,kCAAkC;EAClC,yBAAyB;EACzB,oCAAoC;EACpC,8BAA8B;AAChC","sourcesContent":["@import url('https://fonts.cdnfonts.com/css/roboto');\n\n#root{\n  background-color: #161718;\n  height: 100%;\n}\n.App {\n  background-color: #161718;\n  text-align: center;\n  font-family: 'Roboto', sans-serif;\n  height: 100%;\n}\np{\n  font-size: 18px;\n}\n/*************** For Contact page *************/\n#contact-form div{\n  text-align: left;\n}\n#contact-form div label{\n  margin-left: 10px;\n}\n#contact-form .form-control:focus{\n  border-color: white;\n  box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.5);\n}\n#hide-inputs-btn:hover{\n  background-color: white !important;\n  color: #161718 !important;\n  border: 1px solid #161718 !important;\n  margin-bottom: 20px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
